import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "@reach/router";
import { useLocalization } from "gatsby-theme-i18n";
import { useNavigate } from "../../hooks/navigation";
import SignIn from "../../components/signin";
import { loginThunk, urlRedirect } from "../../redux/actions/auth-actions";
import { dynamicLoadLocale } from "../../utils";
import config from "../../config";

const SignInContainer = ({ isPopup = false }) => {
  const location = useLocation();
  const { locale } = useLocalization();
  const { navigate } = useNavigate();
  const authRef = useRef();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const isAdmin = useSelector((state) => state.user.data.isAdmin);
  const loginStatus = useSelector((state) => state.user.loginStatus);
  const flagCognitoSso = useSelector((state) => state.misc.featureFlags["flag.ops.cognito_sso"]);
  const params = new URLSearchParams(location.search);
  const redirect = params.get("redirect");

  const handleOnRegister = () => {
    if (location.pathname.includes(locale)) {
      const path = location.pathname.substring(location.pathname.indexOf(locale) + locale.length);
      dispatch(urlRedirect(`${path}${location.search}`));
    } else {
      dispatch(urlRedirect(`${location.pathname}${location.search}`));
    }
  };

  const handleLogin = ({ email, password }) => dispatch(loginThunk({ email, password }));

  authRef.current = isAuthenticated;

  useEffect(() => {
    if (isPopup) {
      return;
    }
    const pathname = isAdmin ? "/administrator/home" : "/";
    const { from } = location.state?.from || { from: { pathname } };
    if (isAuthenticated) {
      if (redirect) {
        window.location.href = redirect;
      } else {
        navigate(from.pathname);
        if (isAdmin > 0) {
          dynamicLoadLocale(config.ADMIN_DEFAULT_LOCALE || "en");
        } else {
          dynamicLoadLocale(config.TENANT_DEFAULT_LOCALE || "es");
        }
      }
    }
  }, [isAuthenticated]);

  return (
    <SignIn
      onSubmit={handleLogin}
      onRegister={handleOnRegister}
      status={loginStatus}
      isPopup={isPopup}
      flagCognitoSso={flagCognitoSso}
    />
  );
};

export default SignInContainer;
