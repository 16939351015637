import React from "react";
import SignIn from "/src/containers/signin";
import Navbar from "/src/containers/navbar";
import Contact from "/src/components/home/contact";
import Footer from "/src/components/footer";
import config from "/src/config";
import SEO from "/src/components/seo";
import BacThemeProvider from "/src/providers/bac-theme-provider";
import SetupContainer from "/src/setup-container";

const SigninPage = () => {
  return (
    <>
      <SetupContainer />
      <SEO
        title={`${config.CLIENT_NAME} - Sign in`}
        description={config.CLIENT_DESCRIPTION}
        image={encodeURI(config.LOGO_DARK) || ""}
      />
      <BacThemeProvider>
        <Navbar />
        <SignIn path="/signin" />
        <Contact />
        <Footer />
      </BacThemeProvider>
    </>
  );
};

export default SigninPage;
