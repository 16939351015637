import { Box, Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
// theme
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { t } from "@lingui/macro";
import theme from "../../theme";
// local components
import Link from "../link";
import TextField from "../text-field";
import config from "../../config";

const propTypes = {};
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  box: {
    width: "400px",
    marginTop: theme.spacing(3),
    textAlign: "center",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  form: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3),
  },
  secondaryButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  links: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(1),
  },
  field: {
    marginBottom: 0,
  },
}));
const INITIAL_VALUES = {
  email: "",
  password: "",
};
const LoginComponent = ({
  status = "",
  onSubmit = () => {},
  onRegister = () => {},
  isPopup = false,
  flagCognitoSso,
}) => {
  const classes = useStyles(theme);
  const [values, setValues] = useState(INITIAL_VALUES);
  const handleInputChange =
    (field) =>
    ({ target }) =>
      setValues((state) => ({ ...state, [field]: target.value }));
  const handleSubmit = () => {
    localStorage.setItem("login-mode", "local");
    onSubmit(values);
  };

  const handleOnRegister = () => {
    if (isPopup) {
      onRegister();
    }
  };

  const handleSingleSignOn = () => {
    localStorage.setItem("login-mode", "cognito");

    const language = localStorage.getItem("language");
    const { pathname } = JSON.parse(language);
    const redirectUrl = `redirect_uri=${encodeURIComponent(`${config.HOME_URL}${pathname}home/`)}`;

    window.location.href = `${config.COGNITO_URL}&${redirectUrl}`;
  };

  const { i18n } = useLingui();

  return (
    <>
      <Container className={classes.container}>
        <Box className={classes.box}>
          <Typography variant="h3">{`${i18n._(t`Completa los datos para iniciar sesión`)}`}</Typography>
          <ValidatorForm onSubmit={handleSubmit} className={classes.form}>
            <Box mt={2} display="flex" flexDirection="column">
              <TextField
                inputContainerClassNames={`${classes.field}`}
                label=""
                description=" "
                placeholder="E-mail"
                value={values.email}
                onChange={handleInputChange("email")}
                validators={["required", "isEmail"]}
                data-testid="login-email-textfield"
                errorMessages={[`${i18n._(t`Necesitamos un e-mail`)}`, `${i18n._(t`El e-mail no es válido`)}`]}
                fullWidth
              />
              <TextField
                inputContainerClassNames={`${classes.field}`}
                label=""
                description=" "
                placeholder={`${i18n._(t`Contraseña`)}`}
                type="password"
                value={values.password}
                onChange={handleInputChange("password")}
                validators={["required"]}
                data-testid="login-password-textfield"
                fullWidth
              />
            </Box>
            {status === "error" && (
              <Typography variant="body1" color="error">
                {`${i18n._(t`Lo sentimos, credenciales incorrectas.`)}`}
              </Typography>
            )}
            <Box className={classes.buttonContainer}>
              <Button
                disabled={status === "loading"}
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                style={{ fontSize: "14px" }}
                data-testid="login-submit-button"
                fullWidth
              >
                <Trans>INICIAR SESIÓN</Trans>
                {status === "loading" && "..."}
              </Button>
            </Box>
            {flagCognitoSso && (
              <Box className={classes.secondaryButtonContainer}>
                <Button
                  disabled={status === "loading"}
                  size="large"
                  variant="contained"
                  color="secondary"
                  style={{ fontSize: "14px" }}
                  onClick={handleSingleSignOn}
                  fullWidth
                >
                  <Trans>SINGLE SIGN ON</Trans>
                  {status === "loading" && "..."}
                </Button>
              </Box>
            )}
          </ValidatorForm>
          <Box className={classes.links}>
            <Box onClick={handleOnRegister}>
              <Typography variant="body1">
                <Link to="/signup/">
                  <Trans>¿No tienes cuenta?</Trans>
                </Link>
              </Typography>
            </Box>
            <Box marginX={1} fontWeight="bold">
              |
            </Box>
            <Typography variant="body1">
              <Link to="/forgot/">
                <Trans>No recuerdo mi contraseña</Trans>
              </Link>
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

LoginComponent.propTypes = propTypes;

export default LoginComponent;
